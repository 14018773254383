import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq from '@mq';
import RichText from '@core/RichText';

import Seo from '@core/Seo';

import HouseIllustration from '@components/HouseIllustration';
import Sections from '@components/Sections';

const HousePage = ({
  data: {
    contentfulHousePage: { title, subtitle, description, sections, rooms },
  },
}) => (
  <>
    <Seo
      pageTitle={title}
      pageDescription="Envisioned as a “third place” between home and work, Denizen House is the hangout spot for the neighbourhood."
      pageImage="/seo/eiswerk.jpg"
    />
    <main>
      <section>
        <Title>{title}</Title>
        <Subtitle>
          <RichText text={subtitle} />
        </Subtitle>
        <Description text={description} />
        <IllustrationContainer>
          <HouseIllustration rooms={rooms} />
        </IllustrationContainer>
      </section>
      <Sections sections={sections} pageTitle="eiswerk" />
    </main>
  </>
);

const Title = styled.h2`
  font: var(--font-m);
  text-align: center;
  margin-bottom: var(--space-xxs);

  ${mq.small} {
    font: var(--font-l);
    letter-spacing: var(--letter-spacing-l);
    margin-bottom: var(--space-xs);
  }

  ${mq.medium} {
    font: var(--font-xl);
    letter-spacing: var(--letter-spacing-xl);
  }
`;

const Subtitle = styled.h1`
  grid-column: 1 / -1;
  text-align: center;
  font: var(--font-subtitle);
  letter-spacing: 0;
  margin-bottom: var(--space-xs);

  ${mq.medium} {
    grid-column: 2 / -2;
    margin-bottom: var(--space-s);
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const Description = styled(RichText)`
  grid-column: 1 / -1;
  margin-bottom: var(--space-m);

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const IllustrationContainer = styled.div`
  grid-column: 1 / -1;
`;

export const housePageQuery = graphql`
  query housePageQuery($locale: String) {
    contentfulHousePage(node_locale: { eq: $locale }) {
      node_locale
      title
      rooms {
        id
        title
        text {
          text
        }
      }
      subtitle {
        raw
      }
      description {
        raw
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(HousePage);
