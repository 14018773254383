import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { useSwipeable } from 'react-swipeable';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import HouseSvgComponent from '@illustration-components/house.svg';
import HouseSvgFile from '@illustration-files/house.svg';
import mq, { mqdown } from '@mq';
import { grid, center, layoutWrapper } from '@mixins';

import { isSmallDevice } from '@utils';

const HouseIllustration = ({ rooms }) => {
  const ref = useRef(null);

  const longestRoom = useMemo(
    () => [...rooms].sort((a, b) => b.text.text.length - a.text.text.length)[0],
    [rooms]
  );

  const scrollPrevious = useCallback(() => {
    const currentIndex = parseInt(ref.current.getAttribute('data-room-active'), 10);

    if (currentIndex > 0) {
      ref.current.setAttribute('data-room-active', currentIndex - 1);
    }
  }, []);

  const scrollNext = useCallback(() => {
    const currentIndex = parseInt(ref.current.getAttribute('data-room-active'), 10);

    if (currentIndex < 4) {
      ref.current.setAttribute('data-room-active', currentIndex + 1);
    }
  }, []);

  const handleSwipeStart = useCallback(
    ({ dir, event }) => {
      if (dir === 'Right') {
        event.preventDefault();
        scrollNext();
      } else if (dir === 'Left') {
        event.preventDefault();
        scrollPrevious();
      }
    },
    [scrollNext, scrollPrevious]
  );

  const swipeableHandlers = useSwipeable({
    onSwipeStart: handleSwipeStart,
    preventDefaultTouchmoveEvent: true,
  });

  useEffect(() => {
    if (isSmallDevice) {
      return;
    }

    setTimeout(() => {
      gsap.to('.house-illustration-container', {
        scrollTrigger: {
          trigger: '.house-illustration-container',
          start: 'top bottom-=400px',
          end: 'bottom bottom',
          onUpdate: ({ progress }) => {
            if (progress < 0.2) {
              ref.current.setAttribute('data-room-active', '0');
            } else if (progress < 0.4) {
              ref.current.setAttribute('data-room-active', '1');
            } else if (progress < 0.6) {
              ref.current.setAttribute('data-room-active', '2');
            } else if (progress < 0.8) {
              ref.current.setAttribute('data-room-active', '3');
            } else {
              ref.current.setAttribute('data-room-active', '4');
            }
          },
        },
      });
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      ScrollTrigger.getAll().forEach(trigger => {
        trigger.kill();
      });
    };
  }, []);

  return (
    <Container {...(isSmallDevice && swipeableHandlers)} className="house-illustration-container">
      <StickyContainer ref={ref} data-room-active="0">
        <ScrollContainer>
          <ImageContainer>
            <HouseDots />
            <HouseImage src={HouseSvgFile} alt="denizen house" width="2603" height="1019" />
          </ImageContainer>
        </ScrollContainer>
        <RoomsContainer>
          <RoomSizer>
            <Room>
              <RoomTitle>{longestRoom.title}</RoomTitle>
              <RoomText>{longestRoom.text.text}</RoomText>
            </Room>
          </RoomSizer>

          {rooms.map((room, index) => (
            <Room key={room.id}>
              <RoomTitle>
                <RoomIndex>
                  {' '}
                  {index + 1}/{rooms.length}
                </RoomIndex>
                {room.title}
              </RoomTitle>
              <RoomText>{room.text.text}</RoomText>
            </Room>
          ))}
        </RoomsContainer>
      </StickyContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;

  ${mq.small} {
    height: 400vh;
  }

  ${mqdown.small} {
    touch-action: pan-y;
    margin-bottom: calc(var(--space-xs) * -1);
  }
`;

const StickyContainer = styled.div`
  height: calc(100vh - 3.5rem);
  max-height: calc(100vh - 3.5rem);
  overflow: hidden;
  margin-left: calc(-1 * var(--gutter));
  margin-right: calc(-1 * var(--gutter));
  display: flex;
  flex-direction: column;
  position: relative;

  ${mq.small} {
    height: 100vh;
    max-height: 100vh;
    position: sticky;
    top: 0;
  }

  &[data-room-active='0'] {
    --progress: -1;
  }

  &[data-room-active='1'] {
    --progress: -0.8;
  }

  &[data-room-active='2'] {
    --progress: -0.6;
  }

  &[data-room-active='3'] {
    --progress: -0.4;
  }

  &[data-room-active='4'] {
    --progress: 0;
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
`;

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  transform: translate3d(calc((100% - 100vw) * var(--progress)), 0, 0);
  padding-right: var(--space-xxs);
  padding-left: calc(var(--space-xxs) + 0.125rem);
  transition: transform var(--motion-plus-half) ease-out;
  /* old way of handling where image would define aspect ratio */
  /* display: inline-block; */
  aspect-ratio: 2.5544 / 1;

  ${mq.small} {
    padding-right: var(--space-xxxs);
    padding-left: var(--space-xxxs);
  }
`;

const HouseImage = styled.img`
  max-width: initial;
  height: 100%;
  width: auto;
`;

const HouseDots = styled(HouseSvgComponent)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .house-room {
    opacity: 0;
    transition: opacity var(--motion-double);
  }

  .house-room-0 {
    [data-room-active='0'] & {
      opacity: 1;
    }
  }

  .house-room-1 {
    [data-room-active='1'] & {
      opacity: 1;
    }
  }

  .house-room-2 {
    [data-room-active='2'] & {
      opacity: 1;
    }
  }

  .house-room-3 {
    [data-room-active='3'] & {
      opacity: 1;
    }
  }

  .house-room-4 {
    [data-room-active='4'] & {
      opacity: 1;
    }
  }
`;

const RoomsContainer = styled.div`
  position: relative;
`;

const RoomSizer = styled.div`
  opacity: 0;
  visibility: hidden;
  padding-bottom: var(--space-xs);

  > div {
    position: initial;
  }
`;

const Room = styled.div`
  ${grid};
  ${layoutWrapper};
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--color-background);
  opacity: 0;
  height: 100%;
  transition: opacity 1000ms;
  row-gap: var(--space-xxs);
  align-content: start;

  &:nth-child(2) {
    [data-room-active='0'] & {
      opacity: 1;
    }
  }

  &:nth-child(3) {
    [data-room-active='1'] & {
      opacity: 1;
    }
  }

  &:nth-child(4) {
    [data-room-active='2'] & {
      opacity: 1;
    }
  }

  &:nth-child(5) {
    [data-room-active='3'] & {
      opacity: 1;
    }
  }

  &:nth-child(6) {
    [data-room-active='4'] & {
      opacity: 1;
    }
  }
`;

const RoomTitle = styled.div`
  grid-column: 2/ -1;
  font: var(--font-s);
  position: relative;

  ${mq.small} {
    font: var(--font-m);
  }

  ${mq.medium} {
    grid-column: 2 / 5;
  }

  ${mq.huge} {
    grid-column: 5 / 8;
  }
`;

const RoomIndex = styled.div`
  ${center};
  position: absolute;
  left: calc(-1 * var(--grid-gap));
  transform: translate(-100%, 0);
  font: var(--font-xs);
  height: 3.125rem;
  width: 3.125rem;
  background-color: var(--color-secondary);
  border-radius: 50%;
  opacity: 1 !important;
`;

const RoomText = styled.div`
  font: var(--font-xs);
  grid-column: 2 / -1;
  grid-row: 2;

  ${mq.small} {
    font: var(--font-s);
  }

  ${mq.medium} {
    grid-column: 6 / -1;
    grid-row: 1;
  }

  ${mq.large} {
    grid-column: 5 / -2;
  }

  ${mq.huge} {
    grid-column: 8 / -5;
  }
`;

export default HouseIllustration;
